import HomeHeader from "../../components/Header";
import IntensivekursBlockNEW from "../../components/Home/intensivekurs_new";
import Query from "../../components/Query";
import HOME_QUERY from "../../queries/home";

const NewElements = () => {
  return (
    <>
      <meta name="robots" content="noindex nofollow" />
      <Query query={HOME_QUERY} id={null}>
        {({ data: { home } }) => {
          return (
            <>
              <HomeHeader data={home.data.attributes.headerblock} />
              <IntensivekursBlockNEW
                homeData={home.data.attributes.Intensivecourse}
              />
            </>
          );
        }}
      </Query>
    </>
  );
};

export default NewElements;
