import React from "react";

import Query from "../../components/Query";
import HOME_QUERY from "../../queries/home";

import { Helmet } from "react-helmet-async";

import Push from "../../components/Push";
import StandorteBlock from "../../components/Content/standorte";
import FAQBlock from "../../components/Home/faq";
import LeistungenBlock from "../../components/Home/leistungen";
import TeamBlock from "../../components/Home/team";
import ReferalBlock from "../../components/Home/referal";
import HomeHeader from "../../components/Header";
import IntensivekursBlockNEW from "../../components/Home/intensivekurs_new";

const Home = () => {
  return (
    <Query query={HOME_QUERY} id={null}>
      {({ data: { home } }) => {
        return (
          <>
            <Helmet>
              <title>{home.data.attributes.seo.metaTitle}</title>
              <meta
                name="description"
                content={home.data.attributes.seo.metaDescription}
              />
              <meta
                name="keywords"
                content={home.data.attributes.seo.keywords}
              />
              <link
                rel="canonical"
                href={home.data.attributes.seo.canonicalURL}
              />
              <meta
                property="og:title"
                content={home.data.attributes.seo.metaTitle}
              />
              <meta
                property="og:description"
                content={home.data.attributes.seo.metaDescription}
              />
              <meta property="og:type" content="website" />
              <meta
                property="og:url"
                content={home.data.attributes.seo.canonicalURL}
              />
              <meta
                property="og:image"
                content={
                  process.env.REACT_APP_BACKEND_URL +
                  home.data.attributes.seo.metaImage.data.attributes.url
                }
              />
            </Helmet>

            <HomeHeader data={home.data.attributes.headerblock} />
            <IntensivekursBlockNEW
              homeData={home.data.attributes.Intensivecourse}
            />
            <ReferalBlock
              title={home.data.attributes.referal.title}
              desc={home.data.attributes.referal.descr}
              img={home.data.attributes.referal.image.data.attributes}
            />
            <LeistungenBlock />
            <TeamBlock />
            <StandorteBlock
              title={"Weitere<br/>Standorte"}
              description={
                "Besuchen Sie unsere Fahrschulen in Bergedorf, HafenCity und Geesthacht! Genießen Sie hochwertigen Fahrunterricht und professionelle Unterstützung an jedem Standort."
              }
            />
            <FAQBlock />
            <Push />
          </>
        );
      }}
    </Query>
  );
};

export default Home;
