import { Col, Container, Row } from "react-bootstrap";
import Query from "../Query";
import INTENSIVKURSE_QUERY from "../../queries/home/intensivkurse";
import Anmelden from "../Content/anmelden";

const IntensivekursBlockNEW = ({ homeData }) => {
  const currentDate = new Date().toISOString().split("T")[0];
  return (
    <Query
      query={INTENSIVKURSE_QUERY}
      variables={{ date: currentDate }}
      id={null}
    >
      {({ data: { intensivkurse } }) => {
        console.log("intensivkurse", intensivkurse);

        const groupForYear = intensivkurse.data.slice(1).reduce((acc, item) => {
          const year = new Date(item.attributes.startdate).getFullYear();
          if (!acc[year]) {
            acc[year] = [];
          }
          acc[year].push(item);
          return acc;
        }, {});

        return (
          <section
            className="layout layout__bg layout__bg_primary"
            id="intensivkurs"
          >
            <Container fluid>
              <div>
                <Row className="align-items-center">
                  <Col xs={12} xl={5} className="mb-5 mb-xl-0">
                    <div className="flex-column justify-content-between h-100">
                      <Col className="layout_mb">
                        <h2 data-aos="fade-up">{homeData.title}</h2>
                      </Col>
                      <Col xs={12} sm={11} lg={8} xl={12}>
                        <p
                          className="layout_text"
                          data-aos="fade-up"
                          dangerouslySetInnerHTML={{
                            __html: homeData.description,
                          }}
                        ></p>
                      </Col>
                    </div>
                  </Col>
                  <Col xs={12} sm={11} lg={8} xl={6} className="offset-xxl-1">
                    <div data-aos="fade-up" className="relative">
                      <img
                        className="autoImg layout_image layout_image__round "
                        src={
                          homeData.img?.data?.attributes.url
                            ? process.env.REACT_APP_BACKEND_URL +
                              homeData.img.data.attributes.url
                            : "/new/intensivkurs.webp"
                        }
                        alt="intensivkurs"
                      />
                      {/* <span className="bg-special-green layout_image__round" /> */}
                    </div>
                  </Col>
                </Row>
                <span className="d-flex layout_mt"></span>
                <h3
                  data-aos="fade-up"
                  className="layout_subtitle layout_title layout_mb"
                >
                  Nächster Kurs
                </h3>
                <div data-aos="fade-in" className="layout_border_section">
                  <Row>
                    <Col xs={12} xl={5} className="mb-4 mb-2xl-0">
                      <Row className="row-special flex-xl-column align-items-center align-items-xl-start justify-content-between h-100">
                        <Col xs={12} md={12} lg={8} xl={12} xxl={10}>
                          <div className="w-100 row">
                            <Col xs={3} sm={3} lg={4}>
                              <p
                                data-aos="fade-up"
                                className="layout_text mb-2"
                              >
                                Start
                              </p>
                              <h3 data-aos="fade-up">
                                {new Date(
                                  intensivkurse.data[0].attributes.startdate
                                ).toLocaleDateString("de-DE", {
                                  day: "2-digit",
                                  month: "2-digit",
                                })}
                              </h3>
                            </Col>
                            <Col xs={5} sm={6} lg={4}>
                              <p
                                data-aos="fade-up"
                                className="layout_text mb-2"
                              >
                                Ende
                              </p>
                              <h3 data-aos="fade-up">
                                {new Date(
                                  intensivkurse.data[0].attributes.enddate
                                ).toLocaleDateString("de-DE", {
                                  day: "2-digit",
                                  month: "2-digit",
                                })}
                              </h3>
                            </Col>
                            <Col xs={4} sm={3} lg={4}>
                              <p
                                data-aos="fade-up"
                                className="layout_text mb-2 nowrap"
                              >
                                Freie plätze
                              </p>
                              <h3 data-aos="fade-up">
                                {intensivkurse.data[0].attributes.stillfree}
                              </h3>
                            </Col>
                          </div>
                        </Col>
                        <Col className="d-none d-lg-flex gap-20 justify-content-end justify-content-xl-start align-items-end">
                          <div className="d-flex flex-column flex-xxl-row gap-20 align-items-2xl-center">
                            <div data-aos="fade-up">
                              <Anmelden
                                btn="btn btn__big btn__fill btn__fill_primary"
                                newform={true}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs={12}
                      md={12}
                      xl={7}
                      xxl={6}
                      className="offset-xl-0 offset-xxl-1"
                    >
                      <ul className="layout_list mb-4 mb-2xl-0">
                        {intensivkurse.data[0].attributes.schedule.map(
                          (item) => {
                            return (
                              <li
                                className="layout_list_item__md w-100 row row-special"
                                key={item.id}
                                data-aos="fade-up"
                              >
                                <Col
                                  xs={3}
                                  sm={3}
                                  className="layout_list_marker d-flex align-items-center offset-xl-0"
                                >
                                  {item.day}
                                </Col>
                                <Col xs={5} sm={6} className="text-start grow">
                                  {item.topic}
                                </Col>
                                <Col
                                  xs={4}
                                  sm={3}
                                  className="offset-xl-0 nowrap"
                                >
                                  {item.time}
                                </Col>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </Col>
                    <Col className="d-flex flex-column flex-md-row gap-20 d-xl-none">
                      <div className="d-lg-none">
                        <div data-aos="fade-up">
                          <Anmelden
                            btn="btn btn__big btn__fill btn__fill_primary btn__full"
                            newform={true}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {intensivkurse.data.length > 1 && (
                  <>
                    <span className="d-flex layout_mt"></span>
                    <h3
                      data-aos="fade-up"
                      className="layout_subtitle layout_title layout_mb"
                    >
                      Folgende Kurse
                    </h3>
                    {Object.keys(groupForYear)
                      .sort((a, b) => a - b)
                      .map((year, index) => (
                        <div
                          key={index}
                          data-aos="fade-in"
                          className={`layout_border_section ${
                            index + 1 <= Object.keys(groupForYear).length &&
                            index !== 0 &&
                            "border-top-0"
                          }`}
                        >
                          <Row>
                            <Col xs={12} xl={5} className="mb-4 mb-2xl-0">
                              <h3
                                className="layout_subtitle layout_title flex gap-4 mb-6 md:mb-8 xl:mb-10 text-gray-400 items-center"
                                data-aos="fade-up"
                              >
                                {year}
                              </h3>
                            </Col>
                            <Col
                              xs={12}
                              md={12}
                              xl={7}
                              xxl={6}
                              className="offset-xl-0 offset-xxl-1"
                            >
                              <ul className="layout_list mb-4 mb-2xl-0">
                                {groupForYear[year].map((item, index) => (
                                  <li
                                    className="layout_list_item__md w-100 row row-special"
                                    key={index}
                                    data-aos="fade-up"
                                  >
                                    <Col
                                      xs={8}
                                      sm={9}
                                      className="d-flex align-items-center offset-xl-0"
                                    >
                                      {new Date(
                                        item.attributes.startdate
                                      ).toLocaleDateString("de-DE", {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                      })}
                                      {" - "}
                                      {new Date(
                                        item.attributes.enddate
                                      ).toLocaleDateString("de-DE", {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                      })}
                                    </Col>

                                    <Col
                                      xs={4}
                                      sm={3}
                                      className="offset-xl-0 nowrap"
                                    >
                                      {item.attributes.stillfree}
                                    </Col>
                                  </li>
                                ))}
                              </ul>
                            </Col>
                          </Row>
                        </div>
                      ))}
                  </>
                )}
                <span className="d-flex layout_mt"></span>
                <Row>
                  <Col xs={12} xl={5} className="mb-4 mb-2xl-0">
                    <h2 data-aos="fade-up">Andere Filiale</h2>
                  </Col>
                  <Col
                    xs={12}
                    md={12}
                    xl={7}
                    xxl={6}
                    className="offset-xl-0 offset-xxl-1"
                  >
                    <h4 data-aos="fade-up" className="h3 tt-normal layout_mb">
                      Schau dir die verfügbaren Kurse in unseren weiteren
                      Filialen an.
                    </h4>
                    <div className="d-flex flex-column flex-md-row w-100 gap-20">
                      <div data-aos="fade-up">
                        <a
                          href="https://fahrschule-bergedorf.de/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn__big btn__border btn__border_white_2 btn__full"
                        >
                          Bergedorf
                        </a>
                      </div>
                      <div data-aos="fade-up">
                        <a
                          href="https://fahrschule-hafencity.de/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn__big btn__border btn__border_white_2 btn__full"
                        >
                          Hafencity
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
        );
      }}
    </Query>
  );
};

export default IntensivekursBlockNEW;
